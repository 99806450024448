import {
  FacilityAirport,
  FacilityBikeSharing,
  FacilityBusStop,
  FacilityCafe,
  FacilityCarpoolParking,
  FacilityFuelStation,
  FacilityHotel,
  FacilityMall,
  FacilityMetroStation,
  FacilityMuseum,
  FacilityNature,
  FacilityOther,
  FacilityParkingLot,
  FacilityRestaurant,
  FacilitySport,
  FacilitySupermarket,
  FacilityTaxiStand,
  FacilityTrainStation,
  FacilityTramStop,
  FacilityWifi,
} from "@/assets/imgs/imgs";
import { LOCATION_FACILITY } from "@/core/models/LocationModel";
import { ReactNode } from "react";

export const FACILITY_iCON: Record<LOCATION_FACILITY, ReactNode> = {
  [LOCATION_FACILITY.HOTEL]: <FacilityHotel className="w-[16px]" />,
  [LOCATION_FACILITY.RESTAURANT]: <FacilityRestaurant className="w-[16px]" />,
  [LOCATION_FACILITY.CAFE]: <FacilityCafe className="w-[16px]" />,
  [LOCATION_FACILITY.MALL]: <FacilityMall className="w-[16px]" />,
  [LOCATION_FACILITY.SUPERMARKET]: <FacilitySupermarket className="w-[16px]" />,
  [LOCATION_FACILITY.SPORT]: <FacilitySport className="w-[16px]" />,
  [LOCATION_FACILITY.RECREATION_AREA]: <FacilityOther className="w-[16px]" />,
  [LOCATION_FACILITY.NATURE]: <FacilityNature className="w-[16px]" />,
  [LOCATION_FACILITY.MUSEUM]: <FacilityMuseum className="w-[16px]" />,
  [LOCATION_FACILITY.BIKE_SHARING]: <FacilityBikeSharing className="w-[16px]" />,
  [LOCATION_FACILITY.BUS_STOP]: <FacilityBusStop className="w-[16px]" />,
  [LOCATION_FACILITY.TAXI_STAND]: <FacilityTaxiStand className="w-[16px]" />,
  [LOCATION_FACILITY.TRAM_STOP]: <FacilityTramStop className="w-[16px]" />,
  [LOCATION_FACILITY.METRO_STATION]: <FacilityMetroStation className="w-[16px]" />,
  [LOCATION_FACILITY.TRAIN_STATION]: <FacilityTrainStation className="w-[16px]" />,
  [LOCATION_FACILITY.AIRPORT]: <FacilityAirport className="w-[16px]" />,
  [LOCATION_FACILITY.PARKING_LOT]: <FacilityParkingLot className="w-[16px]" />,
  [LOCATION_FACILITY.CARPOOL_PARKING]: <FacilityCarpoolParking className="w-[16px]" />,
  [LOCATION_FACILITY.FUEL_STATION]: <FacilityFuelStation className="w-[16px]" />,
  [LOCATION_FACILITY.WIFI]: <FacilityWifi className="w-[16px]" />,
};
