import {
  ConnectorCcs1,
  ConnectorCcs2,
  ConnectorChademo,
  ConnectorGbAc,
  ConnectorGbDc,
  ConnectorSchuko,
  ConnectorTesla,
  ConnectorType2,
  ConnectorYazaki,
} from "@/assets/imgs/imgs";
import { CONNECTOR_TYPE } from "@/core/models/ConnectorModel";
import { ReactNode } from "react";

export interface ConnectorTypeInfo {
  name: string;
  icon: (props?: { width: string; height: string }) => ReactNode;
  show: boolean;
}

export const CONNECTOR_TYPE_INFO: Record<CONNECTOR_TYPE, ConnectorTypeInfo> = {
  [CONNECTOR_TYPE.CHADEMO]: { name: "CHAdeMO", icon: (props) => <ConnectorChademo {...props} />, show: true },
  [CONNECTOR_TYPE.CHAOJI]: { name: "ChaoJi", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_A]: { name: "Domestic A", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_B]: { name: "Domestic B", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_C]: { name: "Domestic C", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_D]: { name: "Domestic D", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_E]: { name: "Domestic E", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_F]: {
    name: "Schuko",
    icon: (props) => <ConnectorSchuko {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.DOMESTIC_G]: { name: "Domestic G", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_H]: { name: "Domestic H", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_I]: { name: "Domestic I", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_J]: { name: "Domestic J", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_K]: { name: "Domestic K", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_L]: { name: "Domestic L", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_M]: { name: "Domestic M", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_N]: { name: "Domestic N", icon: () => "", show: false },
  [CONNECTOR_TYPE.DOMESTIC_O]: { name: "Domestic O", icon: () => "", show: false },
  [CONNECTOR_TYPE.GBT_AC]: {
    name: "GBT AC",
    icon: (props) => <ConnectorGbAc {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.GBT_DC]: {
    name: "GBT DC",
    icon: (props) => <ConnectorGbDc {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.IEC_60309_2_single_16]: { name: "Industrial 16A", icon: () => "", show: false },
  [CONNECTOR_TYPE.IEC_60309_2_three_16]: { name: "Industrial 3x16A", icon: () => "", show: false },
  [CONNECTOR_TYPE.IEC_60309_2_three_32]: { name: "Industrial 3x32A", icon: () => "", show: false },
  [CONNECTOR_TYPE.IEC_60309_2_three_64]: { name: "Industrial 3x64A", icon: () => "", show: false },
  [CONNECTOR_TYPE.IEC_62196_T1]: {
    name: "Yazaki",
    icon: (props) => <ConnectorYazaki {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.IEC_62196_T1_COMBO]: {
    name: "CCS",
    icon: (props) => <ConnectorCcs1 {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.IEC_62196_T2]: {
    name: "Type 2",
    icon: (props) => <ConnectorType2 {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.IEC_62196_T2_COMBO]: {
    name: "CCS2",
    icon: (props) => <ConnectorCcs2 {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.IEC_62196_T3A]: { name: "Pantograph Top", icon: () => "", show: false },
  [CONNECTOR_TYPE.IEC_62196_T3C]: { name: "Pantograph Bottom", icon: () => "", show: false },
  [CONNECTOR_TYPE.NEMA_5_20]: { name: "NEMA 5-20", icon: () => "", show: false },
  [CONNECTOR_TYPE.NEMA_6_30]: { name: "NEMA 6-30", icon: () => "", show: false },
  [CONNECTOR_TYPE.NEMA_6_50]: { name: "NEMA 6-50", icon: () => "", show: false },
  [CONNECTOR_TYPE.NEMA_10_30]: { name: "NEMA 10-30", icon: () => "", show: false },
  [CONNECTOR_TYPE.NEMA_10_50]: { name: "NEMA 10-50", icon: () => "", show: false },
  [CONNECTOR_TYPE.NEMA_14_30]: { name: "NEMA 14-30", icon: () => "", show: false },
  [CONNECTOR_TYPE.NEMA_14_50]: { name: "NEMA 14-50", icon: () => "", show: false },
  [CONNECTOR_TYPE.PANTOGRAPH_BOTTOM_UP]: { name: "Pantograph Bottom", icon: () => "", show: false },
  [CONNECTOR_TYPE.PANTOGRAPH_TOP_DOWN]: { name: "Pantograph Top", icon: () => "", show: false },
  [CONNECTOR_TYPE.TESLA_R]: {
    name: "Tesla R",
    icon: (props) => <ConnectorTesla {...props} />,
    show: true,
  },
  [CONNECTOR_TYPE.TESLA_S]: {
    name: "Tesla S",
    icon: (props) => <ConnectorTesla {...props} />,
    show: true,
  },
};
