import { ConnectorTypeInfo } from "@/constants/connectors";
import { locationActions } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { Card } from "primereact/card";

interface ConnectorCardProps {
  id: string;
  connector: ConnectorTypeInfo;
}

export const ConnectorCard = ({ id, connector }: ConnectorCardProps) => {
  const dispatch = useAppDispatch();

  const { tempFilters } = useAppSelector((state) => state.location);
  const { connector_types } = tempFilters;

  const isSelected = connector_types.includes(id);
  const { icon, name } = connector;

  const handleSelect = (id: string) => {
    let newConnectorTypes = [...connector_types];

    if (newConnectorTypes.includes(id)) {
      newConnectorTypes = newConnectorTypes.filter((item) => item !== id);
    } else {
      newConnectorTypes = [...newConnectorTypes, id];
    }

    dispatch(
      locationActions.setTempFilters({
        ...tempFilters,
        connector_types: newConnectorTypes,
      }),
    );
  };

  return (
    <Card
      className={`w-[100px] ${isSelected ? "bg-[var(--surface-300)]" : "bg-[var(--surface-100)]"}`}
      pt={{ body: { className: "p-2" }, content: { className: "p-1" } }}
      onClick={() => handleSelect(id)}
    >
      <div className="flex flex-column gap-1 align-items-center">
        {icon()}
        <span>{name}</span>
      </div>
    </Card>
  );
};
