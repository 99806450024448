import { CONNECTOR_TYPE_INFO } from "@/constants/connectors";
import { CURRENCIES } from "@/constants/currencies";
import { ConnectorsModel } from "@/core/models/ConnectorModel";
import { formatNumber } from "@/utils/numberUtils";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
interface ConnectorEvseProps {
  connector: ConnectorsModel;
  isLast: boolean;
}

const TRANS_KEY = "pages.LocationDetail.tabs.info.CardEvse.ConnectorEvse";

export const ConnectorEvse = ({ connector, isLast }: ConnectorEvseProps) => {
  const { t } = useTranslation();

  const [isVisible] = useState(false);

  if (!connector.standard) return null;

  const connectorInfo = CONNECTOR_TYPE_INFO[connector.standard];

  return (
    <div>
      <div className="flex align-items-center gap-3 px-3">
        <div className="flex justify-between w-full gap-4 flex-wrap">
          <div className="flex gap-3 align-items-center">
            {connectorInfo.icon({ width: "36px", height: "36px" })}
            <div className="flex flex-column gap-2">
              <h4 className="m-0">
                {`${formatNumber(connector.max_electric_power / 1000, {
                  maximumFractionDigits: 2,
                })} kW`}
              </h4>
              <span>{connectorInfo.name}</span>
            </div>
          </div>

          {connector.tariffs.map((tariff) => (
            <div key={tariff.id} className="flex align-items-center gap-2">
              {tariff.elements.map((element, index) => (
                <Fragment key={index}>
                  {element.price_components[0] && (
                    <div>
                      {element.price_components[0].price}{" "}
                      {element.price_components[0].type === "ENERGY"
                        ? `${CURRENCIES[tariff.currency]?.symbol}/kWh`
                        : `${CURRENCIES[tariff.currency]?.symbol}/min`}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          ))}

          {!connector.tariffs.length && (
            <Button outlined disabled>
              {t(`${TRANS_KEY}.noTariff`)}
            </Button>
          )}
          {/* {!!connector.tariffs.length && <Button onClick={() => setisVisible(!isVisible)}> Ver Tarifa</Button>}
           */}
        </div>
      </div>
      {!!connector.tariffs.length && (
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isVisible ? "m-4 opacity-100 max-h-screen" : "opacity-0 max-h-0"
          }`}
        >
          {/* {connector.tariffs.map((tariff) => (
            <div key={tariff.id} className="flex align-items-center gap-2">
              {tariff.elements.map((element, index) => (
                <Fragment key={index}>
                  {element.price_components[0] && (
                    <div>
                      Precio: {element.price_components[0].price}{" "}
                      {element.price_components[0].type === "ENERGY"
                        ? `${CURRENCIES[tariff.currency]?.symbol}/kWh`
                        : `${CURRENCIES[tariff.currency]?.symbol}/min`}
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          ))} */}
        </div>
      )}
      {!isLast && <Divider />}
    </div>
  );
};
