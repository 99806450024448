import { LocationDetailModel, LocationFacilitiesFiltersModel, LocationMapModel } from "@/core/models/LocationModel";
import { apiMaps } from "../api/api";
import { rejectApi, resolveApi } from "../api/apiUtils";
import { ConnectorTypeFilterModel } from "../models/ConnectorModel";

export interface GetMapServiceParams {
  latitude_ne: number;
  longitude_ne: number;
  latitude_sw: number;
  longitude_sw: number;
  zoom: number;
  connector_types?: string[]; // ["CHADEMO", "CCS"]
  facilities?: string[]; // ["HOTEL", "AIRPORT"]
  is_free?: boolean;
  power_min?: number; // kW
  power_max?: number | null; // kW
}

export const getMapService = (data: GetMapServiceParams) =>
  apiMaps.post<LocationMapModel[]>(`/map`, data).then(resolveApi).catch(rejectApi);

export const getLocationDetailService = (locationId: string) =>
  apiMaps.get<LocationDetailModel>(`/locations/${locationId}`).then(resolveApi).catch(rejectApi);

export const getConnectorTypesService = () =>
  apiMaps.get<ConnectorTypeFilterModel[]>(`/connector_types`).then(resolveApi).catch(rejectApi);

export const getFacilitiesService = () =>
  apiMaps.get<LocationFacilitiesFiltersModel[]>(`/facilities`).then(resolveApi).catch(rejectApi);
