import { CONNECTOR_TYPE_INFO } from "@/constants/connectors";
import { useAppSelector } from "@/core/store/store";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectorCard } from "./ConnectorCard/ConnectorCard";

const LIMIT_CONNECTORS = 4;
const TRANS_KEY = "components.Map.FiltersMap.connectors";

export const ConnectorsFilter = () => {
  const { t } = useTranslation();

  const { tempFilters, filtersLists } = useAppSelector((state) => state.location);
  const { connector_types } = tempFilters;

  const connectors = filtersLists.connector_types
    .filter((connector) => CONNECTOR_TYPE_INFO[connector.code].show)
    .map((connector) => ({ key: connector.code, ...CONNECTOR_TYPE_INFO[connector.code] }));

  const firstConnectors = connectors.slice(0, LIMIT_CONNECTORS);
  const restConnectors = connectors.slice(LIMIT_CONNECTORS);

  // Show more connectors if have sele
  const [showMore, setShowMore] = useState(
    connectors.some((connector, index) => index > LIMIT_CONNECTORS && connector_types.includes(connector.key)),
  );

  if (!filtersLists.connector_types.length) {
    return null;
  }

  return (
    <div>
      <Fieldset
        legend={
          <div className="flex align-items-center gap-2">
            <span>{t(`${TRANS_KEY}.title`)}</span>
            <Badge value={connector_types.length} severity="secondary" />
          </div>
        }
      >
        <div className="flex flex-wrap justify-center gap-3 md:gap-4 overflow-hidden">
          {firstConnectors.map(({ key, ...rest }) => (
            <ConnectorCard key={key} id={key} connector={rest} />
          ))}

          {showMore && restConnectors.map(({ key, ...rest }) => <ConnectorCard key={key} id={key} connector={rest} />)}

          <div className="flex justify-center w-full">
            <Button
              label={showMore ? t(`${TRANS_KEY}.showLess`) : t(`${TRANS_KEY}.showMore`)}
              severity="secondary"
              onClick={() => setShowMore(!showMore)}
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
};
