import { GeoJsonProperties } from "geojson";
import Supercluster, { PointFeature } from "supercluster";
import { EvseModel } from "./EvseModel";

export enum LOCATION_STATUS {
  AVAILABLE = "AVAILABLE_STATUS",
  CHARGING = "CHARGING_STATUS",
  RESERVED = "RESERVED_STATUS",
  OUT_OF_ORDER = "OUT_OF_ORDER_STATUS",
}

export interface LocationMapModel {
  id: string;
  latitude: number;
  longitude: number;
  status: LOCATION_STATUS;
  total_evse: number;
}

// Clusters
export interface ClusterLocationProperties extends Omit<LocationMapModel, "lat" | "lng"> {
  cluster?: boolean;
}

export type Clusterer = Supercluster<GeoJsonProperties, ClusterLocationProperties>;

interface KDBush {
  numItems: number;
  nodeSize: number;
  data: number[];
  ids: any;
  coords: any;
  _pos: number;
  _finished: boolean;
}

export interface ClustererCustom extends Clusterer {
  trees: KDBush[];
  getClusters: (bounds: number[], zoom: number) => ClusterLocation[];
}

export type ClusterLocation = PointFeature<ClusterLocationProperties | null>;

export interface LocationDetailModel {
  id: number;
  name: string;
  address: string;
  postal_code: string;
  state: string;
  country: string;
  owner?: LocationDetailOwner;
  coordinates: LocationCoordinates;
  facilities: LOCATION_FACILITY[];
  evses: EvseModel[];
  images: LocationDetailImages;
}

interface LocationDetailImages {
  url: string;
}

interface LocationCoordinates {
  latitude: string;
  longitude: string;
}

interface LocationDetailOwner {
  name: string;
  website: string;
  logo: string;
}

export interface LocationFacilitiesFiltersModel {
  code: string;
  name: LOCATION_FACILITY;
}

export enum LOCATION_FACILITY {
  HOTEL = "HOTEL",
  RESTAURANT = "RESTAURANT",
  CAFE = "CAFE",
  MALL = "MALL",
  SUPERMARKET = "SUPERMARKET",
  SPORT = "SPORT",
  RECREATION_AREA = "RECREATION_AREA",
  NATURE = "NATURE",
  MUSEUM = "MUSEUM",
  BIKE_SHARING = "BIKE_SHARING",
  BUS_STOP = "BUS_STOP",
  TAXI_STAND = "TAXI_STAND",
  TRAM_STOP = "TRAM_STOP",
  METRO_STATION = "METRO_STATION",
  TRAIN_STATION = "TRAIN_STATION",
  AIRPORT = "AIRPORT",
  PARKING_LOT = "PARKING_LOT",
  CARPOOL_PARKING = "CARPOOL_PARKING",
  FUEL_STATION = "FUEL_STATION",
  WIFI = "WIFI",
}
