import errorAnimation from "@/assets/animations/error.json";
import { useEffect } from "react";
import { Trans } from "react-i18next";
import Lottie from "react-lottie";
import { useRouteError } from "react-router-dom";

const { PROD } = import.meta.env;

const TRANS_KEY = "pages.Error";

export const ErrorPage = () => {
  const error = useRouteError() as Error;

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="w-screen h-screen flex flex-column mt-20 align-items-center">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: errorAnimation,
        }}
        isClickToPauseDisabled={true}
        height={300}
        width={300}
      />
      <h1 className="text-center mt-0">
        <Trans i18nKey={`${TRANS_KEY}.title`} />
      </h1>
      {!PROD && <p>{error.message}</p>}
    </div>
  );
};
