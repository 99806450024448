import {
  getConnectorTypesService,
  getFacilitiesService,
  getLocationDetailService,
  getMapService,
  GetMapServiceParams,
} from "@/core/services/locationServices";
import { ThunkAppAction } from "@/core/store/store";
import { locationActions } from "../../slices/locationsSlice";

export const getMapEpic = (data: GetMapServiceParams): ThunkAppAction => {
  return async (dispatch) => {
    const newDate = new Date().getTime();

    dispatch(locationActions.addLoadingMap(newDate));

    if (!data.power_max) {
      const { power_min, power_max, ...rest } = data; // eslint-disable-line
      data = rest;
    }

    getMapService(data)
      .then((locations) => {
        dispatch(locationActions.setLocationsMap(locations));
      })
      .finally(() => dispatch(locationActions.removeLoadingMap(newDate)));
  };
};

export const getLocationDetailEpic = (locationId: string, errorCallback?: () => void): ThunkAppAction => {
  return async (dispatch) => {
    const newDate = new Date().getTime();

    dispatch(locationActions.addLoadingDetail(newDate));
    const requestId = Date.now();
    dispatch(locationActions.setRequestIdDetail(requestId));

    getLocationDetailService(locationId)
      .then((location) => {
        dispatch(locationActions.setLocationDetail({ location, requestId }));
      })
      .catch(() => {
        if (errorCallback) errorCallback();
      })
      .finally(() => dispatch(locationActions.removeLoadingDetail(newDate)));
  };
};

export const loadLocationsFilterEpic = (): ThunkAppAction => {
  return async (dispatch) => {
    dispatch(locationActions.setLoadingFilters(true));

    Promise.all([getConnectorTypesService(), getFacilitiesService()])
      .then(([connector_types, facilities]) => {
        dispatch(
          locationActions.setFiltersLists({
            connector_types,
            facilities,
            power: [0, 7, 22, 55, 200],
            powerLabels: ["slow", "semifast", "fast", "superfast"],
          }),
        );
      })
      .finally(() => dispatch(locationActions.setLoadingFilters(false)));
  };
};
