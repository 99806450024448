import { EVSE_STATUS_COLOR } from "@/constants/evse";
import { EVSE_STATUS, EvseModel } from "@/core/models/EvseModel";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { useTranslation } from "react-i18next";
import { ConnectorEvse } from "./ConnectorEvse/ConnectorEvse";

interface CardEvseProps {
  evse: EvseModel;
  index: number;
}
const TRANS_KEY = "pages.LocationDetail.tabs.info.CardEvse";

export const CardEvse = ({ evse, index }: CardEvseProps) => {
  const { t } = useTranslation();

  return (
    <Card pt={{ content: { className: "p-0" } }}>
      <div className="flex flex-column gap-4">
        <div className="flex align-items-center justify-between">
          <div>{evse.physical_reference || `${t(`${TRANS_KEY}.title`)} ${index + 1}`}</div>
          <Chip
            className={`bg-${EVSE_STATUS_COLOR[evse.status || EVSE_STATUS.UNKNOWN]} text-white`}
            label={t(`location.evse.status.${evse.status || EVSE_STATUS.UNKNOWN}`)}
          />
        </div>
        <div className="flex flex-column gap-3">
          {evse.connectors.map((connector, index) => (
            <ConnectorEvse key={connector.id} connector={connector} isLast={index === evse.connectors.length - 1} />
          ))}

          {!evse.connectors.length && <div className="w-full text-center">{t(`${TRANS_KEY}.noConnectors`)}</div>}
        </div>
      </div>
    </Card>
  );
};
