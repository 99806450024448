import { ClusterLocation } from "@/core/models/LocationModel";

interface ClusterMarkerProps {
  cluster: ClusterLocation;
}

export const ClusterMarker = ({ cluster }: ClusterMarkerProps) => {
  if (!cluster.properties) return null;
  const { total_evse } = cluster.properties;

  return (
    <div className="flex justify-center items-center">
      <div className="w-3rem h-3rem bg-primary rounded-full flex justify-center items-center text-white">
        {total_evse}
      </div>
    </div>
  );
};
