import i18n from "@/i18n/i18n";
import axios from "axios";
import { version } from "../../../package.json";
import { getHostApi } from "./apiUtils";

export const apiMaps = axios.create({
  baseURL: getHostApi("/api-map/v1"),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "APP-VERSION": version,
    "Accept-Language": i18n.language,
  },
});
