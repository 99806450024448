import { ConnectorsModel } from "./ConnectorModel";

export interface EvseModel {
  physical_reference: string;
  status: EVSE_STATUS;
  connectors: ConnectorsModel[];
}

export enum EVSE_STATUS {
  AVAILABLE = "AVAILABLE",
  BLOCKED = "BLOCKED",
  CHARGING = "CHARGING",
  INOPERATIVE = "INOPERATIVE",
  OUTOFORDER = "OUTOFORDER",
  PLANNED = "PLANNED",
  REMOVED = "REMOVED",
  RESERVED = "RESERVED",
  UNKNOWN = "UNKNOWN",
}
