import error404Animation from "@/assets/animations/404.json";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";

const TRANS_KEY = "pages.Error404";

export const Error404Page = () => {
  const { t } = useTranslation();
  return (
    <div className="w-screen h-screen flex flex-column mt-20 align-items-center">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: error404Animation,
        }}
        isClickToPauseDisabled={true}
        height={300}
        width={300}
      />
      <h1 className="text-center">{t(`${TRANS_KEY}.title`)}</h1>
      <p>{t(`${TRANS_KEY}.message`)}</p>
    </div>
  );
};
