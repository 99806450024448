import { MADRID_CENTER } from "@/constants/map";
import { useAppSelector } from "@/core/store/store";

export const useUserLocation = () => {
  const { location } = useAppSelector((state) => state.user);

  const centerToUserLocation = (map: google.maps.Map) => {
    if (!map) return;

    if (location.latitude || location.longitude) {
      map.panTo({
        lat: location.latitude || MADRID_CENTER.lat,
        lng: location.longitude || MADRID_CENTER.lng,
      });
      map.setZoom(12);
    }
  };
  return { userLocation: location, centerToUserLocation };
};
