import { ClusterLocation } from "@/core/models/LocationModel";
import { buildPath } from "@/router/routerUtils";
import { APP_PATH } from "@/router/routes";
import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import { useNavigate } from "react-router-dom";
import { ClusterMarker } from "./LocationMarker/ClusterMarker/ClusterMarker";
import { LocationMarker } from "./LocationMarker/LocationMarker";

export type CustomMarkerProps = {
  cluster: ClusterLocation;
};

export const CustomMarker = ({ cluster }: CustomMarkerProps) => {
  const map = useMap();
  const navigate = useNavigate();

  if (!cluster.properties) return null;

  const { cluster: isCluster, id } = cluster.properties;
  const [lng, lat] = cluster.geometry.coordinates;

  return (
    <AdvancedMarker
      position={{ lat, lng }}
      zIndex={1}
      clickable
      onClick={() => {
        if (!map) return;

        if (isCluster) {
          map.panTo({ lat, lng });
          map.setZoom((map.getZoom() || 0) + 2);
          return;
        }

        if (!id) return;

        navigate(buildPath(APP_PATH.LOCATION, { id }), { state: { noCenter: true } });
      }}
    >
      {isCluster ? <ClusterMarker cluster={cluster} /> : <LocationMarker cluster={cluster} />}
    </AdvancedMarker>
  );
};
