import { ClusterLocationProperties } from "@/core/models/LocationModel";
import { useAppSelector } from "@/core/store/store";
import * as GeoJSON from "geojson";
import { FeatureCollection, Point } from "geojson";
import { useMemo } from "react";
import Supercluster, { ClusterProperties, Options } from "supercluster";

export function useSupercluster(superclusterOptions: Options<ClusterLocationProperties, ClusterProperties>) {
  const { locationsMap } = useAppSelector((state) => state.location);

  const clusterer = useMemo(() => {
    const newCluterer = new Supercluster<ClusterLocationProperties, ClusterLocationProperties>({
      ...superclusterOptions,
      map: (props) => {
        return {
          ...props,
          total_evse: props?.total_evse || 0,
        };
      },
      reduce: (accumulated, props) => {
        accumulated.total_evse += props.total_evse || 0;
      },
    });

    const geojson: FeatureCollection<Point, ClusterLocationProperties> = GeoJSON.default.parse(locationsMap, {
      Point: ["latitude", "longitude"],
    });

    newCluterer.load(geojson.features);
    return newCluterer;
  }, [superclusterOptions, locationsMap]);

  return {
    clusterer,
  };
}
