import { FACILITY_iCON } from "@/constants/location";
import { LOCATION_FACILITY } from "@/core/models/LocationModel";
import { locationActions } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { Badge } from "primereact/badge";
import { Fieldset } from "primereact/fieldset";
import { MultiSelect } from "primereact/multiselect";
import { SelectItem } from "primereact/selectitem";
import { useTranslation } from "react-i18next";

interface FacilityOption extends SelectItem {
  name: LOCATION_FACILITY;
}

const TRANS_KEY = "components.Map.FiltersMap.facilities";

export const FacilitiesFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { tempFilters, filtersLists } = useAppSelector((state) => state.location);
  const { facilities } = tempFilters;

  const facilitiesItems: FacilityOption[] = filtersLists.facilities.map((facility) => ({
    label: t(`location.facilities.${facility.name}`),
    value: facility.name,
    name: facility.name,
  }));

  const handleSelect = (newFacilities: string[]) => {
    dispatch(
      locationActions.setTempFilters({
        ...tempFilters,
        facilities: newFacilities,
      }),
    );
  };

  if (!filtersLists.facilities.length) {
    return null;
  }

  return (
    <div>
      <Fieldset
        legend={
          <div className="flex align-items-center gap-2">
            <span> {t(`${TRANS_KEY}.title`)}</span>
            <Badge value={facilities.length} severity="secondary" />
          </div>
        }
        pt={{
          content: {
            className: "py-2",
          },
        }}
      >
        <div className="flex flex-wrap gap-4 overflow-hidden w-full">
          <MultiSelect
            display="chip"
            className="w-full"
            options={facilitiesItems}
            value={facilities}
            onChange={(e) => handleSelect(e.value)}
            itemTemplate={(option: FacilityOption) => (
              <div className="flex gap-2">
                <div>{FACILITY_iCON[option.name]}</div>
                <div>{option.label}</div>
              </div>
            )}
            placeholder={t(`${TRANS_KEY}.placeholder`)}
            showClear
            filter
            scrollHeight="300px"
            pt={{
              label: {
                className: "flex flex-wrap gap-2",
              },
            }}
          />
        </div>
      </Fieldset>
    </div>
  );
};
