import { APP_PATH } from "./routes";

export const buildPath = (path: APP_PATH, params: Record<string, string | number>): string => {
  let builtPath: string = path;

  for (const [key, value] of Object.entries(params)) {
    builtPath = builtPath.replace(`:${key}`, value.toString());
  }

  return builtPath;
};

export const isSamePath = (currentPath: string, path: string) => {
  const currentPathParts = currentPath.split("/");
  const pathParts = path.split("/");

  if (currentPathParts.length !== pathParts.length) {
    return false;
  }

  let isSame = true;

  for (let i = 0; i < pathParts.length; i++) {
    const currentPathPart = currentPathParts[i];
    const pathPart = pathParts[i];

    if (pathPart === currentPathPart) {
      continue;
    }

    // check parth is all number  an currentPath with regex
    if (currentPath.match(/^\d*/) && pathPart.match(/:\w+/)) {
      continue;
    }

    isSame = false;
  }

  return isSame;
};
