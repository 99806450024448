import errorWarningAnimation from "@/assets/animations/errorWarning.json";
import Lottie from "react-lottie";

interface ErrorAlertProps {
  title?: string;
  message?: string;
}

export const ErrorAlert = ({ title, message }: ErrorAlertProps) => {
  return (
    <div className="flex flex-column align-items-center">
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: errorWarningAnimation,
        }}
        isClickToPauseDisabled={true}
        height={100}
        width={100}
      />
      {title && <h3 className="mb-0">{title} </h3>}
      {message && <p>{message}</p>}
    </div>
  );
};
