import { DeferredContent } from "primereact/deferredcontent";
import { Galleria } from "primereact/galleria";
import { Image } from "primereact/image";
import { useEffect, useRef, useState } from "react";

interface ImageModel {
  src: string;
  srcPreview: string;
  alt: string;
}

export const ImagesTab = () => {
  // TODO: add images
  const [images] = useState<ImageModel[]>([
    {
      src: "https://etecnic.es/wp-content/uploads/2024/10/Diseno-sin-titulo-9.png",
      srcPreview: "https://etecnic.es/wp-content/uploads/2024/10/Diseno-sin-titulo-9.png",
      alt: "Description for Image 1",
    },
    {
      src: "https://etecnic.es/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-09-at-13.43.20-600x800.jpeg",
      srcPreview: "https://etecnic.es/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-09-at-13.43.20-600x800.jpeg",
      alt: "Description for Image 1",
    },
    {
      src: "https://etecnic.es/wp-content/uploads/2024/10/sfsd-600x277.jpg",
      srcPreview: "https://etecnic.es/wp-content/uploads/2024/10/sfsd-600x277.jpg",
      alt: "Description for Image 1",
    },
    {
      src: "https://etecnic.es/wp-content/uploads/2024/10/20241024_ATG_ETECNIC_XCHARGE-1.jpg",
      srcPreview: "https://etecnic.es/wp-content/uploads/2024/10/20241024_ATG_ETECNIC_XCHARGE-1.jpg",
      alt: "Description for Image 1",
    },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef<Galleria>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
      } else if (e.key === "ArrowRight") {
        setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [images]);

  const itemTemplate = (item: ImageModel) => {
    return (
      <DeferredContent>
        <Image
          src={item.src}
          alt={item.alt}
          width="100%"
          pt={{
            image: { className: "max-h-[92vh]" },
          }}
        />
      </DeferredContent>
    );
  };

  return (
    <div className="card flex">
      <Galleria
        ref={galleria}
        value={images}
        style={{ maxWidth: "850px" }}
        activeIndex={activeIndex}
        onItemChange={(e) => setActiveIndex(e.index)}
        circular
        fullScreen
        showItemNavigators
        showIndicators
        showThumbnails={false}
        item={itemTemplate}
        onHide={() => {
          setActiveIndex(0);
        }}
      />
      <div
        className="gap-3"
        style={{
          maxWidth: "400px",
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
      >
        {images &&
          images.map((image, index) => {
            return (
              <DeferredContent key={index}>
                <Image
                  className="w-full h-[124px] object-cover"
                  src={image.srcPreview}
                  alt={image.alt}
                  width="100%"
                  height="124px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setActiveIndex(index);
                    galleria.current?.show();
                  }}
                />
              </DeferredContent>
            );
          })}
      </div>
    </div>
  );
};
