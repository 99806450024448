import { EVSE_STATUS } from "@/core/models/EvseModel";

export const EVSE_STATUS_COLOR: Record<EVSE_STATUS, string> = {
  AVAILABLE: "green-500",
  BLOCKED: "gray-500",
  CHARGING: "cyan-500",
  INOPERATIVE: "gray-500",
  OUTOFORDER: "gray-500",
  PLANNED: "gray-500",
  REMOVED: "gray-500",
  RESERVED: "orange-500",
  UNKNOWN: "gray-500",
};
