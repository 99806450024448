import { ErrorAlert } from "@/components/alerts/ErrorAlert";
import { FACILITY_iCON } from "@/constants/location";
import { MAX_ZOOM } from "@/constants/map";
import { LocationDetailModel } from "@/core/models/LocationModel";
import { getLocationDetailEpic } from "@/core/store/epics/location/locationEpic";
import { locationActions } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { useUserLocation } from "@/hooks/useUserLocation";
import { APP_PATH } from "@/router/routes";
import { useMap } from "@vis.gl/react-google-maps";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { ProgressSpinner } from "primereact/progressspinner";
import { Sidebar } from "primereact/sidebar";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImagesTab } from "./Tabs/ImagesTab/ImagesTab";
import { InfoTab } from "./Tabs/InfoTab/InfoTab";

const TRANS_KEY = "pages.LocationDetail";

export const LocationDetailPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const map = useMap();
  const { state } = useLocation();
  const { centerToUserLocation } = useUserLocation();
  const { t } = useTranslation();

  const { locationDetail, loadingDetail, closeOnEscape } = useAppSelector((state) => state.location);

  const isLoading = loadingDetail.length > 0;

  const totalConnectors = useMemo(
    () => locationDetail?.evses.reduce((acc, evse) => acc + evse.connectors.length, 0) || 0,
    [locationDetail?.evses],
  );

  const [visible, setVisible] = useState(false);
  const [haveError, setHaveError] = useState(false);

  useEffect(() => {
    setVisible(!!id);

    if (id) {
      dispatch(getLocationDetailEpic(id, errorCallback));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!map || !locationDetail || state?.noCenter) return;

    map.panTo({
      lat: Number(locationDetail.coordinates.latitude),
      lng: Number(locationDetail.coordinates.longitude),
    });

    map.setZoom(MAX_ZOOM);
  }, [map, locationDetail, state]);

  useEffect(() => {
    if (haveError && !state?.noCenter && map) {
      centerToUserLocation(map);
    }
  }, [state, haveError, map]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadingDetail.length === 0) return;
    const timeout = setTimeout(() => {
      dispatch(locationActions.removeAllLoadingDetail());

      clearTimeout(timeout);
    }, 60 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [loadingDetail, dispatch]);

  const errorCallback = () => {
    setHaveError(true);
  };
  const handleGoLocationMaps = (locationDetail: LocationDetailModel) => {
    const { latitude, longitude } = locationDetail.coordinates;
    const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(url, "_blank");
  };

  return (
    <Sidebar
      visible={visible}
      dismissable={false}
      onHide={() => {
        setVisible(false);
        setTimeout(() => {
          navigate(APP_PATH.HOME);
        }, 200);
      }}
      closeOnEscape={closeOnEscape}
      modal={false}
      pt={{
        root: {
          className: "rounded-xl w-[92vw] md:w-[450px] max-w-[450px] ",
          style: { position: "absolute", top: "76px", left: "16px", height: "calc(100vh - 92px)" },
        },
        header: { className: "hidden" },
        content: { className: "p-0" },
      }}
    >
      {isLoading && (
        <div className="w-full h-full flex justify-center align-items-center">
          <ProgressSpinner className="w-4rem" />
        </div>
      )}

      {!isLoading && locationDetail && (
        <div
          style={{
            display: "grid",
            height: "100%",
            gridTemplateRows: "auto auto 1fr",
            overflowY: "auto",
          }}
        >
          <div className="flex justify-end sticky top-4 mr-1 z-20 h-0">
            <Button
              className="w-2rem h-2rem "
              onClick={() => {
                setVisible(false);
                setTimeout(() => {
                  navigate(APP_PATH.HOME);
                }, 200);
              }}
              icon={<i className="pi pi-times" />}
              rounded
            />
          </div>
          <header
            style={{
              backgroundImage: `url(https://etecnic.es/wp-content/uploads/2024/10/Caratula-BLOG-etecnic-7.png)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "250px",
              alignItems: "flex-start",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              position: "relative",
            }}
          />
          <main
            style={{
              display: "grid",
              gridTemplateRows: "auto auto 1fr",
            }}
          >
            <h2 className="m-0 sticky top-0 py-3 pl-4 pr-6 bg-[var(--surface-e)] z-10 text-xl text-default">
              {locationDetail.name}
            </h2>
            <div className="px-4 pb-2 flex flex-column gap-3">
              <div className="flex justify-between flex-nowrap gap-1">
                <p className="m-0">
                  {locationDetail.address} {locationDetail.postal_code}
                  {locationDetail.state ? `, ${locationDetail.state}` : ""}
                </p>
                <Button
                  className="w-2rem h-2rem min-w-[2rem] mt-1"
                  onClick={() => handleGoLocationMaps(locationDetail)}
                  icon={<i className="pi pi-directions" />}
                  rounded
                  outlined
                />
              </div>

              {locationDetail.owner?.name && <p className="m-0">{locationDetail.owner.name}</p>}

              {!!locationDetail.facilities.length && (
                <div className="flex flex-wrap gap-2">
                  {locationDetail.facilities.map((facility) => (
                    <Chip
                      key={facility}
                      icon={<div className="mr-2">{FACILITY_iCON[facility]}</div>}
                      label={t(`location.facilities.${facility}`)}
                    />
                  ))}
                </div>
              )}
            </div>
            <TabView
              pt={{
                root: {
                  style: { display: "grid", gridTemplateRows: "auto 1fr" },
                },
                panelContainer: {
                  className: "bg-opacity-40 bg-[var(--surface-100)]",
                },
              }}
            >
              <TabPanel header={`${t(`${TRANS_KEY}.tabs.info.title`)} (${totalConnectors})`}>
                <InfoTab locationDetail={locationDetail} />
              </TabPanel>
              <TabPanel header={t(`${TRANS_KEY}.tabs.images`)}>
                <ImagesTab />
              </TabPanel>
            </TabView>
          </main>
        </div>
      )}

      {!isLoading && !locationDetail && (
        <>
          <div className="flex justify-end sticky top-3 mr-3 z-20 h-0">
            <Button
              className="w-2rem h-2rem "
              onClick={() => {
                setVisible(false);
                setTimeout(() => {
                  navigate(APP_PATH.HOME);
                }, 200);
              }}
              icon={<i className="pi pi-times" />}
              rounded
            />
          </div>
          <div className="w-full h-full flex justify-center align-items-center">
            <ErrorAlert title="Error" message="Hubo un problema al cargar la ubicación" />
          </div>
        </>
      )}
    </Sidebar>
  );
};
