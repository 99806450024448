import { IconCurrentLocation, MapLayerHybrid, MapLayerRoadmap, MapLayers, MapLayerTerrain } from "@/assets/imgs/imgs";
import { DEFAULT_ZOOM, MAX_ZOOM, MIN_ZOOM } from "@/constants/map";
import { locationActions } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { useMap } from "@vis.gl/react-google-maps";
import { Button } from "primereact/button";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";

export const MapControlBottomRight = () => {
  const dispatch = useAppDispatch();
  const map = useMap();

  const { location } = useAppSelector((state) => state.user);
  const { mapType } = useAppSelector((state) => state.location);

  const iconLayer = {
    roadmap: MapLayerRoadmap,
    terrain: MapLayerTerrain,
    hybrid: MapLayerHybrid,
  };

  return (
    <div className="flex flex-column gap-2 m-2">
      <Tooltip target="layers-dial" content="Capas" />
      <SpeedDial
        model={[
          {
            icon: iconLayer["roadmap"],
            className: mapType === "roadmap" ? "bg-[var(--primary-700)]" : "",
            command: () => {
              dispatch(locationActions.setMapType("roadmap"));
            },
          },
          {
            icon: iconLayer["terrain"],
            className: mapType === "terrain" ? "bg-[var(--primary-700)]" : "",
            command: () => {
              dispatch(locationActions.setMapType("terrain"));
            },
          },
          {
            icon: iconLayer["hybrid"],
            className: mapType === "hybrid" ? "bg-[var(--primary-700)]" : "",
            command: () => {
              dispatch(locationActions.setMapType("hybrid"));
            },
          },
        ]}
        showIcon={<MapLayers />}
        direction="up"
        style={{ position: "relative", top: "0", right: "0", width: "48px" }}
        pt={{
          button: {
            root: {
              className: "w-[48px] h-[48px]",
            },
          },
        }}
      />
      {(!!location.latitude || !!location.longitude) && (
        <Button
          data-testid="current-location-button"
          icon={() => <IconCurrentLocation />}
          onClick={() => {
            if (!map) return;

            map.panTo({
              lat: location.latitude || 0,
              lng: location.longitude || 0,
            });
            map.setZoom(14);
          }}
          rounded
        />
      )}
      <Button
        icon={<i className="pi pi-plus" />}
        rounded
        disabled={(map?.getZoom() || DEFAULT_ZOOM) >= MAX_ZOOM}
        onClick={() => {
          if (!map) return;

          map.setZoom((map.getZoom() || DEFAULT_ZOOM) + 1);
        }}
        data-testid="zoom-in-button"
      />
      <Button
        icon={<i className="pi pi-minus" />}
        rounded
        disabled={(map?.getZoom() || DEFAULT_ZOOM) <= MIN_ZOOM}
        onClick={() => {
          if (!map) return;

          map.setZoom((map.getZoom() || DEFAULT_ZOOM) - 1);
        }}
        data-testid="zoom-out-button"
      />
    </div>
  );
};
