import { MADRID_CENTER } from "@/constants/map";
import { useAppSelector } from "@/core/store/store";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import "./CurrentLocationMarker.scss";

export const CurrentLocationMarker = () => {
  const { location } = useAppSelector((state) => state.user);

  return (
    <>
      {(location.latitude || location.longitude) && (
        <AdvancedMarker
          className="current-location-marker"
          position={{ lat: location.latitude || MADRID_CENTER.lat, lng: location.longitude || MADRID_CENTER.lng }}
          clickable={false}
          zIndex={9999}
        >
          <div
            className="relative top-3 left-0 w-2rem h-2rem flex justify-content-center align-items-center rounded-full pulse"
            style={{ zIndex: 9999, backgroundColor: "rgb(59 130 246 / 0.6)" }} // TODO: fix when remove prime flex
          >
            <div className="w-1rem h-1rem  bg-blue-500 rounded-full border-white border-2" />
          </div>
        </AdvancedMarker>
      )}
    </>
  );
};
