import { ConnectorTypeFilterModel } from "@/core/models/ConnectorModel";
import { LocationDetailModel, LocationFacilitiesFiltersModel, LocationMapModel } from "@/core/models/LocationModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LocationSlice {
  loadingMap: number[];
  loadingDetail: number[];
  loadingFilters: boolean;
  locationsMap: LocationMapModel[];
  locationsList: LocationMapModel[];
  closeOnEscape: boolean;
  mapType: "roadmap" | "hybrid" | "terrain";
  filtersLists: {
    connector_types: ConnectorTypeFilterModel[];
    facilities: LocationFacilitiesFiltersModel[];
    power: number[];
    powerLabels: string[];
  };
  filters: LocationsFilters;
  tempFilters: LocationsFilters;
  locationDetail: LocationDetailModel | null;
  requestIdDetail?: number;
}

export interface LocationsFilters {
  latitude_ne: number;
  longitude_ne: number;
  latitude_sw: number;
  longitude_sw: number;
  zoom: number;
  filterNumber: number;
  connector_types: string[]; // ["CHADEMO", "CCS"]
  facilities: string[]; // ["HOTEL", "AIRPORT"]
  is_free: boolean;
  power_min: number; // kW
  power_max: number | null; // kW
}

export const locationFiltersInitialState: LocationsFilters = {
  latitude_ne: 0,
  longitude_ne: 0,
  latitude_sw: 0,
  longitude_sw: 0,
  zoom: 0,
  filterNumber: 0,
  connector_types: [],
  facilities: [],
  is_free: false,
  power_min: 0,
  power_max: null,
};

export const locationSliceInitialState: LocationSlice = {
  loadingMap: [],
  loadingDetail: [],
  loadingFilters: false,
  locationsMap: [],
  locationsList: [],
  closeOnEscape: true,
  mapType: "roadmap",
  filtersLists: {
    connector_types: [],
    facilities: [],
    power: [],
    powerLabels: [],
  },
  filters: locationFiltersInitialState,
  tempFilters: locationFiltersInitialState,
  locationDetail: null,
};

export const locationSlice = createSlice({
  name: "location",
  initialState: locationSliceInitialState,
  reducers: {
    // region Loadings
    addLoadingMap: (state, { payload }: PayloadAction<number>) => {
      state.loadingMap.push(payload);
    },
    removeLoadingMap: (state, { payload }: PayloadAction<number>) => {
      state.loadingMap = state.loadingMap.filter((date) => date !== payload);
    },
    removeAllLoadingMap: (state) => {
      state.loadingMap = [];
    },
    addLoadingDetail: (state, { payload }: PayloadAction<number>) => {
      state.loadingDetail.push(payload);
    },
    removeLoadingDetail: (state, { payload }: PayloadAction<number>) => {
      state.loadingDetail = state.loadingDetail.filter((date) => date !== payload);
    },
    removeAllLoadingDetail: (state) => {
      state.loadingDetail = [];
    },

    setLoadingFilters: (state, { payload }: PayloadAction<LocationSlice["loadingFilters"]>) => {
      state.loadingFilters = payload;
    },
    setCloseOnEscape: (state, { payload }: PayloadAction<LocationSlice["closeOnEscape"]>) => {
      state.closeOnEscape = payload;
    },
    // endregion

    // region Locations
    setLocationsMap: (state, { payload }: PayloadAction<LocationSlice["locationsMap"]>) => {
      state.locationsMap = payload;
    },
    setLocationsList: (state, { payload }: PayloadAction<LocationSlice["locationsList"]>) => {
      state.locationsList = payload;
    },
    // endregion

    // map
    setMapType: (state, { payload }: PayloadAction<LocationSlice["mapType"]>) => {
      state.mapType = payload;
    },

    // region Filters
    setFiltersLists: (state, { payload }: PayloadAction<LocationSlice["filtersLists"]>) => {
      state.filtersLists = payload;
    },
    setFilters: (state, { payload }: PayloadAction<LocationSlice["filters"]>) => {
      let filterNumber = 0;

      if (payload.connector_types.length) {
        filterNumber++;
      }

      if (payload.facilities.length) {
        filterNumber++;
      }

      state.filters = { ...payload, filterNumber };
    },
    setTempFilters: (state, { payload }: PayloadAction<LocationSlice["tempFilters"]>) => {
      state.tempFilters = payload;
    },
    // endregion
    setRequestIdDetail: (state, { payload }: PayloadAction<LocationSlice["requestIdDetail"]>) => {
      state.requestIdDetail = payload;
      state.locationDetail = null;
    },
    setLocationDetail: (
      state,
      {
        payload: { location, requestId },
      }: PayloadAction<{ location: LocationSlice["locationDetail"]; requestId: number }>,
    ) => {
      if (state.requestIdDetail !== requestId) return;

      state.locationDetail = location;
    },
  },
});

export const locationActions = locationSlice.actions;

export default locationSlice;
