import { AxiosResponse } from "axios";

const { VITE_PROTOCOL, VITE_HOST, VITE_PORT } = import.meta.env;

export const getHostApi = (extraPath?: string) => {
  const HOST_APP = VITE_HOST && VITE_PORT ? `${VITE_HOST}:${VITE_PORT}` : VITE_HOST;
  return `${VITE_PROTOCOL}://${HOST_APP}${extraPath || ""}`;
};

export const resolveApi = <T>(res: AxiosResponse<T>) => {
  return res.data;
};

export const rejectApi = (error: any) => {
  console.error(error);
  throw error;
};
