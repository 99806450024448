import { locationActions } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { Fieldset } from "primereact/fieldset";
import Slider from "rc-slider";
import { MarkObj } from "rc-slider/lib/Marks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./PowerFilters.scss";

const TRANS_KEY = "components.Map.FiltersMap.power";

export const PowerFilter = () => {
  const dispath = useAppDispatch();
  const { t } = useTranslation();

  const { tempFilters, filtersLists } = useAppSelector((state) => state.location);
  const { power_min, power_max } = tempFilters;

  const { marks, markMinDefault, markMaxDefault, markMax, label } = useMemo(() => {
    const getPowerLabel = () => {
      const { power, powerLabels } = filtersLists;

      const powerMax = power_max ?? power[power.length - 1];

      const startIndex = power.findIndex((p, index) => power_min >= p && power_min < (power[index + 1] ?? Infinity));
      const endIndex = power.findIndex((p, index) => powerMax > p && powerMax <= (power[index + 1] ?? Infinity));

      const startLabel = powerLabels[startIndex];
      const endLabel = powerLabels[endIndex];

      if (startIndex === endIndex) {
        return t(`${TRANS_KEY}.types.${startLabel}`);
      }

      return `${t(`${TRANS_KEY}.types.${startLabel}`)} - ${t(`${TRANS_KEY}.types.${endLabel}`)}`;
    };

    const maxLength = filtersLists.power.length - 1;
    // Get marks
    const marks: Record<number, MarkObj> = {};

    filtersLists.power.forEach((power, index) => {
      const isLast = index === maxLength;
      marks[index] = {
        label: `${power}${isLast ? "+" : ""}`,
        style: { color: "var(--text-color)", marginTop: "6px", fontSize: "1rem" },
      };
    });

    // Get labels
    const label = getPowerLabel();

    // Get ranges
    const markMinDefault = filtersLists.power.findIndex((power) => power === power_min) ?? 0;

    const markMaxDefault = power_max ? filtersLists.power.findIndex((power) => power === power_max) : maxLength;

    const markMax = maxLength;

    return { marks, markMinDefault, markMaxDefault, markMax, label };
  }, [filtersLists.power, power_min, power_max]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePowerChange = (value: number | number[]) => {
    const [min, max] = value as number[];

    const maxIsLast = max === filtersLists.power.length - 1;
    dispath(
      locationActions.setTempFilters({
        ...tempFilters,
        power_min: filtersLists.power[min],
        power_max: maxIsLast && !min ? null : filtersLists.power[max],
      }),
    );
  };

  if (!filtersLists.power.length) {
    return null;
  }

  return (
    <div>
      <Fieldset
        legend={
          <div className="flex align-items-center gap-2">
            <span>{t(`${TRANS_KEY}.title`)}</span>
          </div>
        }
        pt={{
          content: { className: "pt-0 pb-10" },
        }}
      >
        <div className="flex flex-wrap justify-center gap-3 md:gap-4">
          <div>{label}</div>
          <Slider
            range
            min={0}
            max={markMax}
            defaultValue={[markMinDefault, markMaxDefault]}
            marks={marks}
            step={null}
            allowCross={false}
            pushable={1}
            onChangeComplete={handlePowerChange}
            styles={{
              track: { backgroundColor: "var(--primary-300)" },
              rail: { backgroundColor: "var(--surface-200)" },
              handle: { borderColor: "var(--primary-500)", opacity: 1, width: "20px", height: "20px", top: "2px" },
            }}
          />
        </div>
      </Fieldset>
    </div>
  );
};
