import { ClusterLocation, LOCATION_STATUS } from "@/core/models/LocationModel";

export type LocationMarkerProps = {
  cluster: ClusterLocation;
};
export const LocationMarker = ({ cluster }: LocationMarkerProps) => {
  if (!cluster.properties) return null;

  const { status, total_evse } = cluster.properties;

  const statusColor: Record<LOCATION_STATUS, string> = {
    [LOCATION_STATUS.AVAILABLE]: "green-500",
    [LOCATION_STATUS.CHARGING]: "cyan-500",
    [LOCATION_STATUS.RESERVED]: "orange-500",
    [LOCATION_STATUS.OUT_OF_ORDER]: "gray-500",
  };

  const color = statusColor[status] || statusColor[LOCATION_STATUS.OUT_OF_ORDER];

  return (
    <div className="flex justify-center items-center">
      <div
        className={`w-3rem h-3rem bg-transparent rounded-full border-${color} flex justify-center items-center`}
        style={{ border: "4px solid" }}
      >
        <div className={`w-2rem h-2rem bg-${color} rounded-full flex justify-center items-center text-white`}>
          {total_evse}
        </div>
      </div>
    </div>
  );
};
