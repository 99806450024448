import { LocationDetailModel } from "@/core/models/LocationModel";
import { useTranslation } from "react-i18next";
import { CardEvse } from "./CardEvse/CardEvse";

interface InfoTabProps {
  locationDetail: LocationDetailModel;
}

const TRANS_KEY = "pages.LocationDetail.tabs.info";

export const InfoTab = ({ locationDetail }: InfoTabProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-column gap-3">
      {locationDetail.evses.map((evse, index) => (
        <CardEvse key={evse.physical_reference || index} evse={evse} index={index} />
      ))}

      {!locationDetail.evses.length && <div className="w-full text-center mt-2">{t(`${TRANS_KEY}.noEvses`)}</div>}
    </div>
  );
};
