import { Clusterer, ClustererCustom, ClusterLocation } from "@/core/models/LocationModel";
import { useMapViewport } from "@/hooks/useMapViewport";
import { useEffect, useState } from "react";
import { CustomMarker } from "./CustomMarker";

interface MapsClustersProps {
  clusterer: Clusterer;
}

export const MapClusters = ({ clusterer }: MapsClustersProps) => {
  const { bbox, zoom } = useMapViewport({ padding: 100 });

  const clustererCustom = clusterer as ClustererCustom;

  const [clusters, setclusters] = useState<ClusterLocation[]>([]);

  useEffect(() => {
    if (!clustererCustom.trees[0]) return;

    const newClusters = clustererCustom.getClusters(bbox, zoom);

    setclusters(newClusters);
  }, [clustererCustom, bbox, zoom]);

  return (
    <>
      {clusters.map((cluster) => (
        <CustomMarker key={cluster.id || `location-${cluster.properties?.id}`} cluster={cluster} />
      ))}
    </>
  );
};
