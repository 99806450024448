export const CURRENCIES: Record<string, { symbol: string }> = {
  AED: { symbol: "د.إ." },
  AFN: { symbol: "Af" },
  ALL: { symbol: "L" },
  AMD: { symbol: "֏" },
  ANG: { symbol: "ƒ" },
  AOA: { symbol: "Kz" },
  ARS: { symbol: "AR$" },
  AUD: { symbol: "AU$" },
  AWG: { symbol: "ƒ" },
  AZN: { symbol: "ман" },
  BAM: { symbol: "KM" },
  BBD: { symbol: "BBD$" },
  BDT: { symbol: "৳" },
  BGN: { symbol: "лв." },
  BHD: { symbol: "BD" },
  BIF: { symbol: "FBu" },
  BMD: { symbol: "$" },
  BND: { symbol: "B$" },
  BOB: { symbol: "Bs." },
  BRL: { symbol: "R$" },
  BSD: { symbol: "$" },
  BTN: { symbol: "Nu." },
  BWP: { symbol: "P" },
  BYN: { symbol: "Br" },
  BZD: { symbol: "BZ$" },
  CAD: { symbol: "CA$" },
  CDF: { symbol: "FC" },
  CHF: { symbol: "Fr." },
  CKD: { symbol: "$" },
  CLP: { symbol: "CL$" },
  CNY: { symbol: "CN¥" },
  COP: { symbol: "CO$" },
  CRC: { symbol: "₡" },
  CUC: { symbol: "CUC$" },
  CUP: { symbol: "$MN" },
  CVE: { symbol: "CV$" },
  CZK: { symbol: "Kč" },
  DJF: { symbol: "Fdj" },
  DKK: { symbol: "kr." },
  DOP: { symbol: "RD$" },
  DZD: { symbol: "DA" },
  EGP: { symbol: "E£" },
  EHP: { symbol: "Ptas." },
  ERN: { symbol: "Nkf" },
  ETB: { symbol: "Br" },
  EUR: { symbol: "€" },
  FJD: { symbol: "FJ$" },
  FKP: { symbol: "FK£" },
  FOK: { symbol: "kr" },
  GBP: { symbol: "£" },
  GEL: { symbol: "₾" },
  GGP: { symbol: "£" },
  GHS: { symbol: "GH₵" },
  GIP: { symbol: "£" },
  GMD: { symbol: "D" },
  GNF: { symbol: "FG" },
  GTQ: { symbol: "Q" },
  GYD: { symbol: "G$" },
  HKD: { symbol: "HK$" },
  HNL: { symbol: "L" },
  HRK: { symbol: "kn" },
  HTG: { symbol: "G" },
  HUF: { symbol: "Ft" },
  IDR: { symbol: "Rp" },
  ILS: { symbol: "₪" },
  IMP: { symbol: "£" },
  INR: { symbol: "Rs." },
  IQD: { symbol: "د.ع." },
  IRR: { symbol: "﷼" },
  ISK: { symbol: "kr" },
  JEP: { symbol: "£" },
  JMD: { symbol: "J$" },
  JOD: { symbol: "JD" },
  JPY: { symbol: "¥" },
  KES: { symbol: "KSh" },
  KGS: { symbol: "с" },
  KHR: { symbol: "៛" },
  KID: { symbol: "$" },
  KMF: { symbol: "CF" },
  KPW: { symbol: "₩" },
  KRW: { symbol: "₩" },
  KWD: { symbol: "KD" },
  KYD: { symbol: "CI$" },
  KZT: { symbol: "₸" },
  LAK: { symbol: "₭N" },
  LBP: { symbol: "LL." },
  LKR: { symbol: "Rs." },
  LRD: { symbol: "L$" },
  LSL: { symbol: "L" },
  LYD: { symbol: "LD" },
  MAD: { symbol: "DH" },
  MDL: { symbol: "L" },
  MGA: { symbol: "Ar" },
  MKD: { symbol: "den" },
  MMK: { symbol: "Ks" },
  MNT: { symbol: "₮" },
  MOP: { symbol: "MOP$" },
  MRU: { symbol: "UM" },
  MUR: { symbol: "Rs." },
  MVR: { symbol: "MRf" },
  MWK: { symbol: "MK" },
  MXN: { symbol: "MX$" },
  MYR: { symbol: "RM" },
  MZN: { symbol: "MTn" },
  NAD: { symbol: "N$" },
  NGN: { symbol: "₦" },
  NIO: { symbol: "C$" },
  NOK: { symbol: "kr" },
  NPR: { symbol: "Rs." },
  NZD: { symbol: "NZ$" },
  OMR: { symbol: "OR" },
  PAB: { symbol: "B/." },
  PEN: { symbol: "S/." },
  PGK: { symbol: "K" },
  PHP: { symbol: "₱" },
  PKR: { symbol: "Rs." },
  PLN: { symbol: "zł" },
  PND: { symbol: "$" },
  PRB: { symbol: "р." },
  PYG: { symbol: "₲" },
  QAR: { symbol: "QR" },
  RON: { symbol: "L" },
  RSD: { symbol: "din" },
  RUB: { symbol: "₽" },
  RWF: { symbol: "FRw" },
  SAR: { symbol: "SR" },
  SBD: { symbol: "SI$" },
  SCR: { symbol: "Rs." },
  SDG: { symbol: "£SD" },
  SEK: { symbol: "kr" },
  SGD: { symbol: "S$" },
  SHP: { symbol: "£" },
  SLL: { symbol: "Le" },
  SLS: { symbol: "Sl" },
  SOS: { symbol: "Sh.So." },
  SRD: { symbol: "Sr$" },
  SSP: { symbol: "SS£" },
  STN: { symbol: "Db" },
  SVC: { symbol: "₡" },
  SYP: { symbol: "LS" },
  SZL: { symbol: "L" },
  THB: { symbol: "฿" },
  TJS: { symbol: "SM" },
  TMT: { symbol: "m." },
  TND: { symbol: "DT" },
  TOP: { symbol: "T$" },
  TRY: { symbol: "TL" },
  TTD: { symbol: "TT$" },
  TVD: { symbol: "$" },
  TWD: { symbol: "NT$" },
  TZS: { symbol: "TSh" },
  UAH: { symbol: "₴" },
  UGX: { symbol: "USh" },
  USD: { symbol: "$" },
  UYU: { symbol: "$U" },
  UZS: { symbol: "сум" },
  VED: { symbol: "Bs." },
  VES: { symbol: "Bs.F" },
  VND: { symbol: "₫" },
  VUV: { symbol: "VT" },
  WST: { symbol: "T" },
  XAF: { symbol: "Fr" },
  XCD: { symbol: "$" },
  XOF: { symbol: "₣" },
  XPF: { symbol: "₣" },
  YER: { symbol: "YR" },
  ZAR: { symbol: "R" },
  ZMW: { symbol: "ZK" },
  ZWB: { symbol: "" },
  ZWL: { symbol: "Z$" },
  Abkhazia: { symbol: "" },
  Artsakh: { symbol: "դր." },
};
