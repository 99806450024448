import { APP_PATH } from "@/router/routes";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const LocationsPage = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  return (
    <div data-testid="locations-page">
      <Sidebar
        appendTo={document.getElementById("#home-content")}
        visible={visible}
        dismissable={false}
        position="right"
        onHide={() => {
          setVisible(false);

          navigate(APP_PATH.HOME);
        }}
        modal={false}
        style={{
          width: "25vw",
          position: "absolute",
          top: "60px",
          right: "0",
        }}
      >
        <h2>Listado de Ubicaciones</h2>
        <p>En Desarrollo</p>
      </Sidebar>
    </div>
  );
};
