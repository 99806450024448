import { ErrorAlert } from "@/components/alerts/ErrorAlert";
import { getMapEpic } from "@/core/store/epics/location/locationEpic";
import { locationActions, locationFiltersInitialState } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { APP_PATH } from "@/router/routes";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Sidebar } from "primereact/sidebar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ConnectorsFilter } from "./ConnectorsFilter/ConnectorsFilter";
import { FacilitiesFilter } from "./FacilitiesFilter/FacilitiesFilter";
import { PowerFilter } from "./PowerFilter/PowerFilter";

const TRANS_KEY = "components.Map.FiltersMap";

export const FiltersMap = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { filters, tempFilters, loadingFilters } = useAppSelector((state) => state.location);

  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [finishTimeout, setFinishTimeout] = useState(false);

  const marginRight = pathname === APP_PATH.LOCATIONS_LIST ? "calc(25vw + 64px)" : "64px";

  useEffect(() => {
    if (!loadingFilters) return;

    const timeout = setTimeout(() => {
      dispatch(locationActions.setLoadingFilters(false));
      setFinishTimeout(true);
      clearTimeout(timeout);
    }, 30 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [loadingFilters, dispatch]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleResize = (e: MediaQueryListEvent) => setIsMobile(e.matches);

    setIsMobile(mediaQuery.matches); // Comprobar el estado inicial
    mediaQuery.addEventListener("change", handleResize); // Escuchar cambios

    return () => mediaQuery.removeEventListener("change", handleResize); // Limpieza
  }, []);

  const handleFilter = () => {
    setOverlayVisible(false);

    dispatch(locationActions.setFilters(tempFilters));
    dispatch(getMapEpic(tempFilters));
  };

  const handleClear = () => {
    setOverlayVisible(false);

    const { latitude_ne, latitude_sw, longitude_ne, longitude_sw, zoom } = filters;
    const newFilters = { ...locationFiltersInitialState, latitude_ne, latitude_sw, longitude_ne, longitude_sw, zoom };

    dispatch(locationActions.setFilters(newFilters));
    dispatch(locationActions.setTempFilters(newFilters));
    dispatch(getMapEpic(newFilters));
  };

  return (
    <>
      <Button
        icon="pi pi-filter"
        rounded
        onClick={() => {
          setOverlayVisible(!isOverlayVisible);
        }}
      >
        {!!filters.filterNumber && <Badge value={`${filters.filterNumber}`} severity="secondary" />}
      </Button>

      <Sidebar
        visible={isOverlayVisible}
        onHide={() => {
          setOverlayVisible(false);
          dispatch(locationActions.setTempFilters(filters));
        }}
        header={<h2>{t(`${TRANS_KEY}.title`)}</h2>}
        dismissable={false}
        modal={isMobile}
        position="right"
        className="flex flex-col w-[100vw] md:w-[620px] h-[100vh] md:h-[calc(100%-120px-24px)]"
        style={{
          margin: isMobile ? "0" : `120px ${marginRight} 24px 0`,
          transition: isOverlayVisible ? "margin 1s ease" : "",
        }}
        pt={{
          root: { className: "bg-[var(--surface-100)]" },
          header: { className: "pb-0" },
          content: { className: "pr-1" },
        }}
      >
        {loadingFilters && (
          <div className="w-full h-full flex justify-center align-items-center">
            <ProgressSpinner className="w-4rem" />
          </div>
        )}

        {!loadingFilters && !finishTimeout && (
          <div className="grid-rows-[auto_48px] gap-4 h-full" style={{ display: "grid" }}>
            <main className="overflow-auto pr-4 flex flex-column gap-2">
              <FacilitiesFilter />
              <ConnectorsFilter />
              <PowerFilter />
            </main>
            <footer className="flex flex-wrap justify-content-center gap-2">
              <Button
                icon="pi pi-trash"
                label={t(`${TRANS_KEY}.clear`)}
                severity="secondary"
                rounded
                onClick={handleClear}
              />
              <Button icon="pi pi-filter" label={t(`${TRANS_KEY}.action`)} rounded onClick={handleFilter} />
            </footer>
          </div>
        )}

        {!loadingFilters && finishTimeout && (
          <div className="w-full h-full flex justify-center align-items-center">
            <ErrorAlert title={t(`${TRANS_KEY}.error.title`)} message={t(`${TRANS_KEY}.error.message`)} />
          </div>
        )}
      </Sidebar>
    </>
  );
};
