import { combineReducers, configureStore, ThunkAction, UnknownAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import locationSlice from "./slices/locationsSlice";
import uiSlice from "./slices/uiSlice";
import userSlice, { UserSlice } from "./slices/userSlice";

const persistConfig = {
  key: `user`,
  storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  user: persistReducer<UserSlice>(persistConfig, userSlice.reducer),
  ui: uiSlice.reducer,
  location: locationSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const mockStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    devTools: false,
    reducer: rootReducer,
    preloadedState,
  });
};

export const persistor = persistStore(store);

export type StoreReducer = keyof ReturnType<typeof rootReducer>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppAction = ThunkAction<void, RootState, unknown, UnknownAction>;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
