import { AppHeader } from "@/components/AppHeader/AppHeader";
import { MapClusters } from "@/components/Map/ClusterMap/MapClusters";
import { CurrentLocationMarker } from "@/components/Map/CurrentLocationMarker/CurrentLocationMarker";
import { DEFAULT_ZOOM, MADRID_CENTER, MIN_ZOOM } from "@/constants/map";
import { getMapEpic, loadLocationsFilterEpic } from "@/core/store/epics/location/locationEpic";
import { locationActions } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { useSupercluster } from "@/hooks/useSuperCluster";
import { useTheme } from "@/hooks/useTheme";
import { useUserLocation } from "@/hooks/useUserLocation";
import { isSamePath } from "@/router/routerUtils";
import { APP_PATH } from "@/router/routes";
import { Map, MapEvent, useMap } from "@vis.gl/react-google-maps";
import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MAX_ZOOM } from "../../constants/map";
import { MapControls } from "./MapControls/MapControls";

const { VITE_GOOGLE_MAP_ID } = import.meta.env;

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const { theme } = useTheme();
  const map = useMap();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { userLocation, centerToUserLocation } = useUserLocation();

  const { filters, tempFilters, mapType } = useAppSelector((state) => state.location);

  const { clusterer } = useSupercluster({
    extent: 256,
    radius: 80,
    maxZoom: MAX_ZOOM,
  });

  // Clean state when load first time
  useEffect(() => {
    if (state) {
      navigate(pathname, { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!map) return;

    dispatch(loadLocationsFilterEpic());

    if (isSamePath(pathname, APP_PATH.LOCATION)) return;

    centerToUserLocation(map);
  }, [map, userLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoadLocations = (event: MapEvent) => {
    const map = event.map;
    if (!map) return;

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      const newLimit = map.getBounds()?.toJSON();
      const zoom = map.getZoom() || 0;

      const newFilters = {
        ...filters,
        latitude_ne: newLimit?.north || 0,
        longitude_ne: newLimit?.east || 0,
        latitude_sw: newLimit?.south || 0,
        longitude_sw: newLimit?.west || 0,
        zoom,
      };

      const { latitude_ne, longitude_ne, latitude_sw, longitude_sw } = newFilters;

      dispatch(
        locationActions.setTempFilters({
          ...tempFilters,
          latitude_ne,
          longitude_ne,
          latitude_sw,
          longitude_sw,
          zoom,
        }),
      );

      dispatch(locationActions.setFilters(newFilters));
      dispatch(getMapEpic(newFilters));
    }, 100);
  };

  return (
    <div data-testid="home-page">
      <AppHeader />
      <Map
        mapId={VITE_GOOGLE_MAP_ID}
        defaultCenter={MADRID_CENTER}
        mapTypeId={mapType}
        defaultZoom={DEFAULT_ZOOM}
        gestureHandling="greedy"
        disableDefaultUI={true}
        colorScheme={theme.toUpperCase()}
        style={{
          height: "calc(100vh - 60px)",
          width: `${pathname === APP_PATH.LOCATIONS_LIST ? 75 : 100}vw`,
          transition: "width 0.5s ease",
        }}
        reuseMaps
        minZoom={MIN_ZOOM}
        maxZoom={MAX_ZOOM}
        onIdle={handleLoadLocations}
      >
        <MapClusters clusterer={clusterer} />
        <MapControls />
        <CurrentLocationMarker />
      </Map>
      <Outlet />
    </div>
  );
};
