import { LoadingBar } from "@/assets/imgs/imgs";
import { locationActions } from "@/core/store/slices/locationsSlice";
import { useAppDispatch, useAppSelector } from "@/core/store/store";
import { ControlPosition, MapControl } from "@vis.gl/react-google-maps";
import { useEffect } from "react";
import { MapControlBottomRight } from "./MapControlBottomRight/MapControlBottomRight";
import { MapControlTopRight } from "./MapControlTopRight/MapControlTopRight";

export const MapControls = () => {
  const dispatch = useAppDispatch();
  const { loadingMap } = useAppSelector((state) => state.location);

  // Remove loading map after 30s
  useEffect(() => {
    if (loadingMap.length === 0) return;
    const timeout = setTimeout(() => {
      dispatch(locationActions.removeAllLoadingMap());

      clearTimeout(timeout);
    }, 30 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [loadingMap, dispatch]);

  return (
    <>
      <MapControl position={ControlPosition.TOP_RIGHT}>
        <MapControlTopRight />
      </MapControl>

      {/* Loader */}
      <MapControl position={ControlPosition.BOTTOM_CENTER}>
        {loadingMap.length > 0 && (
          <div className="flex m-2 p-2 rounded-full surface-0 ">
            <LoadingBar />
          </div>
        )}
      </MapControl>

      {/* Map controls */}
      <MapControl position={ControlPosition.INLINE_END_BLOCK_END}>
        <MapControlBottomRight />
      </MapControl>
    </>
  );
};
